// @flow

import { graphql } from "gatsby";
import Image from "gatsby-image";
import React, { useState, useEffect } from "react";
import { t, useLocale, addLocale, gettext, ngettext, msgid } from "ttag";
import {
    Banner,
    PropertyAssets,
    PropertyMainInformation,
    TextualInformation,
    PropertyImageCarousel,
    UspTiles,
    Room,
    Shower,
    HabitableSurface,
    Garden,
    Lightbox,
} from "@2po-c21/components";
import {
    Section,
    PropertyDescription,
    PageContent,
    PropertyDetailsHeader,
    CarouselFallbackImage,
} from "@containers";
import {
    AgencyContactForm,
    GeneralInformation,
    PropertyDetailsBreadcrumb,
    PropertyDetailsMeta,
    PropertyDetailsProjectPropertiesDisplay as ProjectPropertiesDisplay,
    PropertyHeader,
    PropertyLocation,
    PropertyVisualInformation,
    SimilarPropertiesDisplay,
} from "@components";
import { Row, Col } from "reactstrap";
import { energyUtils, toYesNoNull, toYesNoMaybeNull } from "@utils";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
    type ImagesSliderEvent,
    type SliderImageClickEvent,
    type DetailElementClickEvent,
    EVENT_MAP,
    type PageImpressionParams,
} from "../models/events";
import { PushEvent, TrackPageImpression } from "../components/Tracker/Tracker";
import Render from "../components/Contentful";
import { PropertyBanner } from "../components";

const toFixedImages = (images) =>
    images &&
    images.map((i) => ({
        description: i.description,
        // eslint-disable-next-line react/display-name
        imageSrc: () => (
            <Image fixed={i.fixed} alt={i.description} backgroundColor={true} />
        ),
    }));

const toFluidImages = (images) =>
    images &&
    images
        .map((i) => ({ ...i, aspectRatio: +i.aspectRatio }))
        .map((i) => ({
            description: i.description,
            // eslint-disable-next-line react/display-name
            imageSrc: () => (
                <Image
                    fluid={i.fluid}
                    alt={i.description}
                    backgroundColor={true}
                    imgStyle={{
                        objectFit: "contain",
                    }}
                />
            ),
        }));

const PropertyDetailsPageTemplate = ({
    data: {
        property,
        agencyFallbackImage,
        carouselFallbackImage: {
            childImageSharp: { fluid },
        },
        c21Translation: { translations },
        contentfulNavigation: { navigationItems },
        footerItems,
        contentfulWebLandingPage: { pageContent },
    },
    pageContext: { translationSlugs, country },
}: *) => {
    const {
        visualImages,
        carouselImages,
        assets,
        address,
        agent,
        agency,
        locale,
        energySpecifications,
        condition,
        metaImage,
        status,
    } = property;
    const gtmDispatcher = useGTMDispatch();

    addLocale(property.locale, translations);
    useLocale(property.locale);

    const [hasMounted, setHasMounted] = useState(false);
    const [lightboxState, setLightboxState] = useState({
        isOpen: false,
        activeContent: null,
    });

    useEffect(() => {
        const params: PageImpressionParams = {
            agency: {
                id: agency?.api_id,
                name: agency?.name,
                postalcode: agency?.address.postalCode,
                city: agency?.address.city,
                country: agency?.address.country,
            },
            agencypage: "false",
            language: locale,
            pagename: "Property details",
            typetemplate: "property-details",
        };
        TrackPageImpression(gtmDispatcher, EVENT_MAP.PageImpression, params);
    }, []);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    const badge =
        condition === "NEW" || status === "OPTION"
            ? {
                  text:
                      status === "OPTION"
                          ? gettext("api.property.status.OPTION")
                          : gettext("api.property.condition.NEW"),
                  variant: status === "OPTION" ? "info" : "",
              }
            : {};

    const propertyImageCarouselContent = (activeIndex) => {
        return (
            <PropertyImageCarousel
                badge={badge}
                images={toFluidImages(carouselImages)}
                largeImage
                reactToArrowKeys={true}
                initialIndex={activeIndex}
                disableReset={true}
                handleOnNext={() =>
                    handleOnNext(property, "property detail overlay")
                }
                handleOnPrevious={() =>
                    handleOnPrevious(property, "property detail overlay")
                }
            />
        );
    };

    const openLightbox = (contentSource) => {
        setLightboxState({
            ...lightboxState,
            isOpen: true,
            activeContent: contentSource(),
        });
    };

    const fallbackImage = <CarouselFallbackImage fluid={fluid} />;

    const showBanner = ["HOUSE", "APARTMENT"].includes(property.type);
    const EMPTY_VALUE = "UNKNOWN";

    const propertyExteriorInfo = exteriorInformation(property);
    const propertyInteriorInfo = interiorInformation(property);

    const breadCrumbPropertyTitle =
        property.title || property.subType || property.type;

    const pushImagesSlider = (
        direction: string,
        projectid: string,
        listname: string,
    ) => {
        const gtmEvent: ImagesSliderEvent = {
            direction,
            listname: listname,
            propertyid: projectid,
            propertyview: "detail",
            type: EVENT_MAP.ImagesSliderEvent,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    const handleOnNext = (property: any, listname: string) => {
        pushImagesSlider("right", property.id, listname);
    };
    const handleOnPrevious = (property: any, listname: string) => {
        pushImagesSlider("left", property.id, listname);
    };
    const handleOnClick = (
        imageKeyIndex: number,
        listname: string = "property detail",
    ) => {
        const image =
            property.carouselImages[imageKeyIndex]?.fixed?.src || undefined;
        const amountImages = property.carouselImages.length;

        const gtmEvent: SliderImageClickEvent = {
            name: image,
            propertyid: property.id,
            listtotal: amountImages,
            propertyview: "detail",
            listname,
            position: imageKeyIndex + 1,
            type: EVENT_MAP.SliderImageClickEvent,
        };

        PushEvent(gtmEvent, gtmDispatcher);
    };

    const onShowMore = () => {
        const gtmEvent: DetailElementClickEvent = {
            name: "read more",
            element: "button",
            targeturl: "#",
            propertyid: property.id,
            type: EVENT_MAP.DetailElementClickEvent,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    const checkUrbanPlanningInfo = (property) => {
        if (property.listingType === "FOR_RENT") {
            return urbanPlanningInformation(property)
                .map((item) => item.value)
                .some(
                    (value) =>
                        value === null ||
                        value === undefined ||
                        value ===
                            t`property-details.urban-planning-information.not-provided`,
                );
        }
    };

    return (
        <PageContent
            locale={locale}
            navigationItems={navigationItems}
            translationSlugs={translationSlugs}
            footerItems={footerItems}
            Breadcrumb={
                <PropertyDetailsBreadcrumb
                    locale={locale}
                    propertyTitle={breadCrumbPropertyTitle}
                />
            }
            country={country}
        >
            <PropertyDetailsMeta
                property={property}
                translationSlugs={translationSlugs}
                locale={locale}
                metaImage={metaImage[0] ? metaImage[0].meta.src : fluid.src}
            />
            {hasMounted && (
                <Lightbox
                    isOpen={lightboxState.isOpen}
                    contentSource={() => lightboxState.activeContent}
                    onClickOutside={() =>
                        setLightboxState({
                            ...lightboxState,
                            isOpen: false,
                        })
                    }
                />
            )}
            <Section>
                <PropertyHeader>
                    <PropertyDetailsHeader property={property} />
                    <Row>
                        <Col xl={{ size: 7, order: 1 }}>
                            {carouselImages.length > 0 ? (
                                <PropertyImageCarousel
                                    handleOnNext={() =>
                                        handleOnNext(
                                            property,
                                            "property detail",
                                        )
                                    }
                                    handleOnPrevious={() =>
                                        handleOnPrevious(
                                            property,
                                            "property detail",
                                        )
                                    }
                                    images={toFluidImages(carouselImages)}
                                    reactToArrowKeys={!lightboxState.isOpen}
                                    onClick={(key) => {
                                        handleOnClick(key);
                                        openLightbox(() =>
                                            propertyImageCarouselContent(key),
                                        );
                                    }}
                                />
                            ) : (
                                fallbackImage
                            )}
                        </Col>
                        <Col xl={{ size: 5, order: 0 }}>
                            <PropertyMainInformation
                                title={property.title}
                                subtitle={`${gettext(
                                    `api.property.listing-type.${property.listingType}`,
                                )}`}
                                condition={
                                    condition === EMPTY_VALUE
                                        ? null
                                        : gettext(
                                              `api.property.condition.${condition}`,
                                          )
                                }
                                address={address}
                                addressHiddenText={gettext(
                                    "common.address.on-demand",
                                )}
                                price={property.price}
                                epcLabel={
                                    property.energySpecifications.energyLabel
                                }
                                badge={badge}
                                urlVirtualVisit={property.urlVirtualVisit}
                                urlVirtualVisitLabel={gettext(
                                    "property-details.general-information.virtualVisit",
                                )}
                            />
                        </Col>
                    </Row>
                </PropertyHeader>
            </Section>
            {showBanner && (
                <Banner>
                    <Banner.Item
                        icon={Room}
                        iconSize={"xl"}
                        type={ngettext(
                            msgid`property-details.banner-icon.bedroom`,
                            `property-details.banner-icon.bedrooms`,
                            property.rooms.numberOfBedrooms || 0,
                        )}
                        value={property.rooms.numberOfBedrooms || 0}
                    />
                    {property.rooms?.numberOfBathrooms != 0 &&
                        renderBathroomBannerInfo(
                            property.rooms.numberOfBathrooms,
                        )}
                    {property.surface?.surfaceAreaGarden &&
                        renderGardenBannerInfo(
                            property.surface.surfaceAreaGarden,
                        )}
                    {property.surface?.totalSurfaceArea &&
                        renderSurfaceBannerInfo(
                            property.surface.totalSurfaceArea,
                        )}
                </Banner>
            )}
            <Section>
                <PropertyDescription
                    title={t`property-details.description.title`}
                    subTitle={t`property-details.description.sub-title`}
                    description={property.description}
                    agent={agent}
                    agency={agency}
                    fallbackImage={agencyFallbackImage.childImageSharp}
                    contactFormAnchor={AgencyContactForm.anchor}
                    propertyId={property.id}
                />
            </Section>
            {property.project ? (
                <Section>
                    <ProjectPropertiesDisplay property={property} />
                </Section>
            ) : null}
            <PropertyVisualInformation
                images={toFixedImages(visualImages)}
                onShowMore={onShowMore}
                onClick={(key: number) => {
                    handleOnClick(key, "property detail images");
                    openLightbox(() => propertyImageCarouselContent(key));
                }}
            />
            <Section>
                <Row>
                    <Col md="6">
                        <GeneralInformation property={property} />
                    </Col>
                    <Col md="6">
                        <TextualInformation
                            title={t`property-details.energy-information.title`}
                            rows={energyInformation(energySpecifications)}
                        />
                    </Col>
                </Row>
                <Row>
                    {propertyInteriorInfo.length > 0 && (
                        <Col md="6">
                            <TextualInformation
                                title={t`property-details.interior-information.title`}
                                rows={propertyInteriorInfo}
                            />
                        </Col>
                    )}
                    {propertyExteriorInfo.length > 0 && (
                        <Col md="6">
                            <TextualInformation
                                title={t`property-details.exterior-information.title`}
                                rows={propertyExteriorInfo}
                            />
                        </Col>
                    )}
                </Row>

                {!checkUrbanPlanningInfo(property) && (
                    <Row>
                        {checkRowValues(urbanPlanningInformation(property)) && (
                            <Col md="6">
                                <TextualInformation
                                    title={t`property-details.urban-planning-information.title`}
                                    rows={urbanPlanningInformation(property)}
                                />
                            </Col>
                        )}
                    </Row>
                )}
            </Section>
            {property &&
                !property?.address?.hidden &&
                property.location &&
                !isDefaultLocation(property.location) && (
                    <Section theme={"themeSecondary"} enableLogo>
                        <PropertyLocation property={property} />
                    </Section>
                )}
            {assets && assets.length ? (
                <Section>
                    <PropertyAssets
                        sectionTitle={t`property-details.assets.title`}
                        assets={assets}
                    />
                </Section>
            ) : undefined}
            {property && (
                <Section>
                    <SimilarPropertiesDisplay property={property} />
                </Section>
            )}
            {pageContent &&
                renderPropertyDetailsBanner(
                    pageContent?.filter((item) => {
                        if (
                            item.__typename === "ContentfulSearchPageBanner" &&
                            [
                                "ALL",
                                process.env.GATSBY_COUNTRY_CODE?.toUpperCase() ||
                                    "",
                            ].includes(item.country)
                        ) {
                            return item;
                        }
                    }),
                    locale,
                    property.listingType,
                )}

            {agency && (
                <AgencyContactForm
                    agencyApiId={agency.api_id}
                    locale={locale}
                    type={"property"}
                    property={property}
                    propertyAddressHidden={property?.address?.hidden}
                />
            )}
            {renderUsp()}
        </PageContent>
    );
};

const energySpecificationsConverter = energyUtils.unitsConverter;

const energySourcesDictionary = energyUtils.energySourcesDictionary;

const checkRowValues = (rows) => {
    return rows.map((row) => row.value).some((value) => value !== null);
};

const buildEnergyValueLabel = (energyTotal) =>
    energyTotal
        ? energySpecificationsConverter[energyTotal.unit](energyTotal.value)
        : t`property-details.energy-information.not-provided`;

const buildEnergySourceList = (energySources) =>
    energySources
        .map((energySource) => energySourcesDictionary[energySource.name]())
        .join(" - ");

const asM2 = (surface) => {
    if (!surface) return null;

    return energySpecificationsConverter[surface.unit](surface.value);
};

const energyInformation = (energySpecifications) => {
    return [
        {
            label: t`property-details.energy-information.class`,
            value: energySpecifications.energyLabel
                ? energySpecifications.energyLabel
                : t`property-details.energy-information.not-provided`,
        },
        {
            label: t`property-details.energy-information.rapport-PEB`,
            value: energySpecifications.energyReportReference
                ? energySpecifications.energyReportReference
                : t`property-details.energy-information.not-provided`,
        },
        {
            label: t`property-details.energy-information.total-energy`,
            value: buildEnergyValueLabel(
                energySpecifications.totalEnergyConsumption,
            ),
        },
        {
            label: t`property-details.energy-information.energy-score`,
            value: buildEnergyValueLabel(energySpecifications.energyScore),
        },
        {
            label: t`property-details.energy-information.CO2-emmission`,
            value: buildEnergyValueLabel(energySpecifications.co2Emission),
        },
        {
            label: t`property-details.energy-information.sources`,
            value: energySpecifications.energySources?.length
                ? buildEnergySourceList(energySpecifications.energySources)
                : "",
        },
    ];
};

const interiorInformation = (property) => {
    return [
        {
            label: t`property-details.interior-information.surface-total`,
            value:
                property?.surface?.totalSurfaceArea?.value &&
                property.surface.totalSurfaceArea.value != 0
                    ? asM2(property.surface.totalSurfaceArea)
                    : undefined,
        },
        {
            label: t`property-details.interior-information.surface-habitable`,
            value:
                property.surface.habitableSurfaceArea?.value &&
                property.surface.habitableSurfaceArea.value != 0
                    ? asM2(property.surface.habitableSurfaceArea)
                    : undefined,
        },
        {
            label: t`property-details.interior-information.bathroom-number`,
            value: property.rooms.numberOfBathrooms,
        },
        {
            label: t`property-details.interior-information.bedroom-number`,
            value: property.rooms.numberOfBedrooms,
        },
        {
            label: t`property-details.interior-information.cellar`,
            value: toYesNoNull(property.amenities.cellar),
        },
    ].filter(({ value }) => !!value);
};

const exteriorInformation = (property) => {
    return [
        {
            label: t`property-details.exterior-information.terrace`,
            value: toYesNoNull(property.amenities.terrace),
        },
        {
            label: t`property-details.exterior-information.garden-orientation`,
            value: property.orientationGarden,
        },
        {
            label: t`property-details.exterior-information.pool`,
            value: toYesNoNull(property.amenities.swimmingPool),
        },
    ].filter(({ value }) => !!value);
};

const urbanPlanningInformation = (property) => {
    return [
        {
            label: t`property-details.general-information.urban-planning-information`,
            value: property.destination
                ? gettext(
                      `api.property.characterised-residential-aria.${property.destination}`,
                  )
                : t`property-details.general-information.not-provided`,
        },
        {
            label: t`property-details.urban-planning-information.building-permission`,
            value:
                toYesNoNull(property.hasBuildingPermit) ||
                t`property-details.urban-planning-information.not-provided`,
        },
        {
            label: t`property-details.urban-planning-information.subdivision-authorization`,
            value:
                toYesNoNull(property.hasSubdivisionAuthorization) ||
                t`property-details.urban-planning-information.not-provided`,
        },
        {
            label: t`property-details.urban-planning-information.preemption-right`,
            value:
                toYesNoNull(property.hasPreemptionRight) ||
                t`property-details.urban-planning-information.not-provided`,
        },
        {
            label: t`property-details.urban-planning-information.floodZone`,
            value:
                toYesNoMaybeNull(property.isFloodZone) ||
                t`property-details.urban-planning-information.not-provided`,
        },
        {
            label: t`property-details.urban-planning-information.summon`,
            value: gettext(`common.text.${property.summon.toLowerCase()}`),
        },
    ].filter(({ value }) => !!value);
};

const renderBathroomBannerInfo = (bathroom) =>
    !bathroom ? undefined : (
        <Banner.Item
            icon={Shower}
            iconSize={"xl"}
            type={ngettext(
                msgid`property-details.banner-icon.bathroom`,
                `property-details.banner-icon.bathrooms`,
                bathroom,
            )}
            value={bathroom}
        />
    );

const renderGardenBannerInfo = (gardenSurface) =>
    gardenSurface.value === 0 ? undefined : (
        <Banner.Item
            icon={Garden}
            iconSize={"xl"}
            type={t`property-details.banner-icon.surface-area-garden`}
            value={energySpecificationsConverter[gardenSurface.unit](
                gardenSurface.value,
            )}
        />
    );

const renderSurfaceBannerInfo = (surface) =>
    surface.value === 0 ? undefined : (
        <Banner.Item
            icon={HabitableSurface}
            iconSize={"xl"}
            type={t`property-details.banner-icon.total-surface-area`}
            value={energySpecificationsConverter[surface.unit](surface.value)}
        />
    );

const renderUsp = () => (
    <UspTiles
        tiles={[
            { data: t`usp.network.title`, label: t`usp.network.subtitle` },
            { data: t`usp.sales.title`, label: t`usp.sales.subtitle` },
            {
                data: t`usp.relationships.title`,
                label: t`usp.relationships.subtitle`,
            },
        ]}
    />
);

const renderPropertyDetailsBanner = (searchBanner, locale, filter) => {
    if (searchBanner?.length) {
        const filteredBanners = (type) =>
            searchBanner?.filter((item) => item.typeOfSearch === type);
        let toRender = null;

        if (filter == "FOR_RENT")
            toRender = Render(filteredBanners("For rent"), locale);

        if (filter == "FOR_SALE")
            toRender = Render(filteredBanners("For sale"), locale);

        return (
            <PropertyBanner>
                {Render(filteredBanners("All"), locale)}
                {toRender}
            </PropertyBanner>
        );
    } else {
        return null;
    }
};

export default PropertyDetailsPageTemplate;

/**
 * Detect the default property location in belgium
 */
const isDefaultLocation = (location) =>
    location?.latitude === 50.85 && location?.longitude === 4.35;

export const query = graphql`
    query ($id: String!, $locale: String!, $country: String!) {
        agencyFallbackImage: file(relativePath: { eq: "fallback.png" }) {
            childImageSharp {
                fixed(width: 100, height: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        property: c21Property(id: { eq: $id }, locale: { eq: $locale }) {
            id
            api_id
            location {
                latitude
                longitude
            }
            amenities {
                cellar
                parking
                swimmingPool
                terrace
            }
            orientationGarden
            destination
            locale
            availability
            yearOfConstruction
            urlVirtualVisit
            numberOfFacades
            hasSubdivisionAuthorization
            hasPreemptionRight
            hasBuildingPermit
            title
            isFloodZone
            condition
            floorNumber
            reference
            hasParking
            description
            type
            subType
            status
            listingType
            price
            availableFrom
            summon
            agency {
                api_id
                slug
                name
                address {
                    city
                    countryCode
                    postalCode
                    street
                    number
                }
                images {
                    description
                    fixed(width: 100, height: 100) {
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            agent {
                firstName
                lastName
                images {
                    description
                    fixed(width: 100, height: 100) {
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            energySpecifications {
                energyLabel
                energyScore {
                    unit
                    value
                }
                energyReportReference
                energySources {
                    name
                }
                totalEnergyConsumption {
                    unit
                    value
                }
                co2Emission {
                    unit
                    value
                }
            }
            surface {
                habitableSurfaceArea {
                    unit
                    value
                }
                surfaceAreaGarden {
                    unit
                    value
                }
                surfaceAreaLivingRoom {
                    unit
                    value
                }
                surfaceAreaKitchen {
                    unit
                    value
                }
                totalSurfaceArea {
                    unit
                    value
                }
            }
            rooms {
                numberOfBathrooms
                numberOfBedrooms
            }
            address {
                street
                number
                box
                postalCode
                city
                country
                hidden
            }
            metaImage: images {
                meta: fixed(width: 600, height: 314) {
                    src
                }
            }
            visualImages: images {
                description
                fixed(width: 270, height: 160) {
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                }
                fluid(maxWidth: 1134) {
                    aspectRatio
                    sizes
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                }
            }
            carouselImages: images {
                description
                fixed(width: 670, height: 384) {
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                }
                fluid(maxWidth: 1134) {
                    aspectRatio
                    sizes
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                }
            }
            assets {
                name
                href
                description
            }
            project {
                slug
                properties {
                    id
                    type
                    subType
                    address {
                        street
                        number
                        box
                        postalCode
                        city
                        country
                    }
                    surface {
                        habitableSurfaceArea {
                            unit
                            value
                        }
                        surfaceAreaGarden {
                            unit
                            value
                        }
                    }
                    images {
                        description
                        fixed(width: 310, height: 177) {
                            width
                            height
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                        }
                    }
                    rooms {
                        numberOfBathrooms
                        numberOfBedrooms
                    }
                    price
                    energySpecifications {
                        energyLabel
                        energyScore {
                            unit
                            value
                        }
                        energyReportReference
                        energySources {
                            name
                        }
                        totalEnergyConsumption {
                            unit
                            value
                        }
                        co2Emission {
                            unit
                            value
                        }
                    }
                    slug
                    numberOfFacades
                    condition
                    hasParking
                }
            }
        }
        carouselFallbackImage: file(relativePath: { eq: "no-image.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 670, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        c21Translation(locale: { eq: $locale }) {
            translations(
                keys: [
                    "property-details.*"
                    "common.text.read-more"
                    "common.text.show-more"
                    "common.text.and"
                    "common.text.yes"
                    "common.text.no"
                    "common.text.unknown"
                    "common.text.maybe"
                    "common.text.language"
                    "common.price.on-demand"
                    "common.address.on-demand"
                    "common.year"
                    "common.gas"
                    "common.electricity"
                    "common.heat-pump"
                    "common.fuel"
                    "common.social.*"
                    "common.print"
                    "usp.*"
                    "api.*"
                    "form.agency-contact.*"
                    "slug.privacy-policy"
                    "slug.property-overview"
                    "slug.property-detail"
                    "favourites.*"
                    "title.property-detail *"
                    "breadcrumbs.property*"
                    "agency.header.button.text"
                    "slug.estimation-tool"
                ]
            )
        }
        contentfulNavigation(
            title: { eq: "Main Navigation" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        footerItems: contentfulNavigation(
            title: { eq: "Footer" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        contentfulWebLandingPage(
            internalLabel: { eq: "Property detail page" }
            node_locale: { eq: $locale }
        ) {
            pageContent {
                ... on ContentfulSearchPageBanner {
                    id
                    typeOfSearch
                    imageLink {
                        ... on ContentfulLink {
                            id
                            linkTitle
                            externalLink
                            contentfulInternalLink {
                                ... on ContentfulWebLandingPage {
                                    slug
                                }
                            }
                        }
                    }
                    image {
                        fluid(maxWidth: 2344, quality: 80) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        title
                        description
                    }
                    country
                }
            }
        }
    }
`;
